//Libraries
import React, { FunctionComponent } from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import cs from 'classnames'

//Hooks
import { useImageQuery } from '../../hooks/useImageQuery'

//Types
import { DirectionCardTypes } from '../z_components-types'

//Styles
import styles from './css/directionCard.module.scss'

//Components
import IconWithText from '../free/iconWithText'

const DirectionCard: FunctionComponent<DirectionCardTypes> = ({
  img,
  title,
  infoFirstLine = '',
  infoSecondLine = '',
  icon = '',
  links,
  isImgContain = 'No',
}) => {
  //Checks if relative img path or vanilla gatsby image
  const images = typeof img === 'string' && useImageQuery()

  return (
    <div className={styles.directionCard}>
      <Img
        className={cs(
          styles.cardImage,
          isImgContain === 'Yes' ? styles.contain : ''
          )}
        fluid={
          typeof img === 'string'
            ? images[img].childImageSharp.fluid
            : img.childImageSharp.fluid
        }
      />

      <h3 className={styles.cardTitle}>{title}</h3>

      <IconWithText icon={icon} text={[infoFirstLine, infoSecondLine]} />

      {links && (
        <nav className={styles.cardNav}>
          {links.map((item, index: number) =>
            item.link.startsWith('http') || item.link.startsWith('www') ? (
              <a className={styles.cardLink} key={index} href={item.link}>
                {item.name}
              </a>
            ) : (
              <Link className={styles.cardLink} key={index} to={item.link}>
                {item.name}
              </Link>
            )
          )}
        </nav>
      )}
    </div>
  )
}

export default DirectionCard
