//Libraries
import React, { FunctionComponent } from 'react'
import cs from 'classnames'

//Types
import { IconWithTextTypes } from '../z_components-types'

//Styles
import styles from './css/iconWithText.module.scss'

const IconWithText: FunctionComponent<IconWithTextTypes> = ({
  icon,
  text,
  links,
  emails,
  tels,
}) => (
  <div className={styles.iconWithText}>
    <i className={cs(icon, styles.icon)}></i>
    <span>
      {text && text.map((text, index) => <p key={index}>{text}</p>)}
      { links &&
        links.map(
          ({url, urlText}, index) =>
            <a key={index} href={url}>
              {urlText}
            </a> 
        )
      }
      {emails &&
        emails.map(
          (email, index) =>
            email && (
              email === 'N/A' ? (
                <p key={index}>{email}</p>
              ) :
              <a key={index} href={`mailto:${email}`}>
                {email}
              </a>
            )
        )}
      {tels &&
        tels.map(
          (tel, index) =>
            tel && (
              <a key={index} href={`tel:${tel.replace(/[a-zA-Z: ]/g, '')}`}>
                {tel}
              </a>
            )
        )}
    </span>
  </div>
)

export default IconWithText
