//Libraries
import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

//Context
import { FontSizeProvider } from '../context/fontSizeContext'

//Types
import { PageWithLinksAndSectionTypes, CommunityPartnerType } from './z_page-types'

//Styles
import styles from './css-pages/pages.module.scss'

//Components
import Header from '../components/header/header'
import Layout from '../components/layout/layout'
import Footer from '../components/footer/footer'
import PageWithLinks from '../components/templates/pageWithLinks'
import DirectionCard from '../components/free/directionCard'

const CommunityPartners: FunctionComponent<PageWithLinksAndSectionTypes> = ({
  data,
}) => {
  const { allMarkdownRemark } = data
  const { edges } = allMarkdownRemark

  return (
    <FontSizeProvider>
        <Helmet htmlAttributes={{lang: 'en'}}>
          <meta charSet="utf-8" />
          <title>OOA - Community Partners</title>
          <link
            rel="canonical"
            href="https://ooa.csi.edu/community-partners/"
          />
          <meta
            name="description"
            content="The Office on Aging has teamed up with different agencies to provide the best care possible for the Magic Valley. Below you will see our community partners and the amazing services they offer."
          />
        </Helmet>

      <Header />

      <Layout>
        <PageWithLinks
          html={data.markdownRemark.html}
          frontmatter={data.markdownRemark.frontmatter}
        >
          {data.markdownRemark.html && <hr />}

          <article className={styles.communityPartnersList}>
          {edges
            .sort((a, b) =>
              a.node.frontmatter.name < b.node.frontmatter.name ? -1 : 1
            )
            .map((partner: CommunityPartnerType, index: number) => {
              const { name, img, address, city, isImgContain } = partner.node.frontmatter
              return (
                  <DirectionCard
                    key={index}
                    title={name}
                    img={img}
                    isImgContain={isImgContain}
                    icon="ri-home-4-line"
                    infoFirstLine={address}
                    infoSecondLine={city}
                    links={[
                      {
                        name: 'See details',
                        link: `${partner.node.fields.slug}`,
                      }
                    ]}
                  />
                  )
                })}
            </article>
        </PageWithLinks>
      </Layout>

      <Footer />
    </FontSizeProvider>
  )
}

export default CommunityPartners

export const communityPartnersQuery = graphql`
  query communityPartners {
    markdownRemark(
      frontmatter: { template_key: { eq: "community-partners-page" } }
    ) {
      html
      frontmatter {
        title
        links {
          link_name
          link
        }
        img {
          childImageSharp {
            fluid(maxWidth: 1700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { template_key: { eq: "community-partner" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            name
            isImgContain
            img {
              childImageSharp {
                fluid(maxWidth: 1700, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            address
            city
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
